import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import DashboardHeader from "components/Common/DashboardHeader";
import AdminSideBar from "components/Admin/SideBar";
import Typography from "components/Common/Typography";
import SettingsNavigation from "components/Admin/Settings/SettingsNavigation";
import {
  AdminGeneralSettingsFormData,
  AdminGeneralSettingsRequiredFormData,
} from "types-definition/admin";
import SettingsGeneralForm from "components/Admin/Settings/SettingsGeneralForm";
import Button from "components/Form/Button";
import {
  GET_USER_ADMIN_SETTINGS_URL,
  GET_USER_SETTINGS_URL,
  UPDATE_ADMIN_USER_DETAILS,
  UPDATE_USER_DETAILS,
} from "api/urls";
import { getRequest, patchRequest } from "api/request";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PageLoader from "components/Common/PageLoader";
import { getUserTypes } from "utils/localstorage";
import { toastError, toastSuccess } from "utils/toast";
import ScrollToTop from "components/Common/ScrollToTop";
import { ADMIN } from "types-definition/constants";
import validator from "utils/validateFields";
import scrolltoView from "utils/scrollToView";

const AdminGeneralSettings: React.FC = () => {
  const navigate = useNavigate();
  const user = getUserTypes();
  const [formData, setFormData] = useState<AdminGeneralSettingsFormData>({
    name: "",
    administratorEmail: "",
    autoDisplayOfTagInfo: false,
    checkCallProximityRadius: "1000",
    officeEmailAddress: "",
    officePhoneNumber: "",
    proximityRadiusEmail: "",
    randomiseEmployeePasscode: true,
    shiftEndProximityRadius: "1000",
    shiftStartProximityRadius: "1000",
  });
  const [requiredFormData, setRequiredFormData] =
    useState<AdminGeneralSettingsRequiredFormData>({
      officeEmailAddress: false,
      officePhoneNumber: false,
      shiftEndProximityRadius: false,
      shiftStartProximityRadius: false,
      checkCallProximityRadius: false,
      proximityRadiusEmail: false,
    });

  const [touchedFormData, setTouchedFormData] = useState<any>({
    officeEmailAddress: false,
    proximityRadiusEmail: false,
  });

  const [requestLoading, setRequestLoading] = useState(false);
  const [settingsId, setSettingsId] = useState("");
  const handleSubmitForm = async () => {
    const { status, data, id } = validator(formData, requiredFormData);
    if (!status) {
      setRequiredFormData((prevData) => {
        return { ...prevData, ...data };
      });
      if (id) {
        scrolltoView(id);
      }
      toast.error("Complete all required fields before saving", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setTouchedFormData((n: any) =>
      Object.fromEntries(
        JSON.parse(JSON.stringify(Object.entries(n))).map((property: any) => [
          property[0],
          true,
        ]),
      ),
    );
    if (
      !formData?.proximityRadiusEmail
        ?.split(",")
        .every((email) => emailRegex.test(email?.trim()))
    ) {
      toast.error("Proximity radius email not valid", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }

    if (!emailRegex.test(formData.officeEmailAddress)) {
      toast.error("Company Email address not valid", {
        duration: 4000,
        position: "top-center",
      });
      return;
    }

    setRequestLoading(true);
    let response: any;
    if (user === ADMIN) {
      response = await patchRequest(`${UPDATE_USER_DETAILS}/${settingsId}`, {
        officePhoneNumber: formData.officePhoneNumber,
        officeEmailAddress: formData.officeEmailAddress,
        shiftStartProximityRadius: formData.shiftStartProximityRadius,
        shiftEndProximityRadius: formData.shiftEndProximityRadius,
        autoDisplayTagInfo: formData.autoDisplayOfTagInfo,
        randomizeEmployeePassCode: formData.randomiseEmployeePasscode,
        checkCallProximityRadius: formData.checkCallProximityRadius,
        proximityRadiusEmail: formData.proximityRadiusEmail,
      });
    } else {
      response = await patchRequest(UPDATE_ADMIN_USER_DETAILS, {
        officePhoneNumber: formData.officePhoneNumber,
        officeEmailAddress: formData.officeEmailAddress,
        shiftStartProximityRadius: formData.shiftStartProximityRadius,
        shiftEndProximityRadius: formData.shiftEndProximityRadius,
        autoDisplayTagInfo: formData.autoDisplayOfTagInfo,
        randomizeEmployeePassCode: formData.randomiseEmployeePasscode,
        checkCallProximityRadius: formData.checkCallProximityRadius,
        proximityRadiusEmail: formData.proximityRadiusEmail,
      });
    }
    if (response?.success) {
      toastSuccess("Settings Updated");
      navigate("/admin/settings/time-check");
    } else {
      let message = response?.data;
      if (response?.data.startsWith(`"proximityRadiusEmail"`)) {
        message = message.replace(
          `"proximityRadiusEmail"`,
          `"Proximity Radius Email"`,
        );
      }

      toast.error(message, {
        duration: 4000,
        position: "top-center",
      });
    }
    setRequestLoading(false);
  };
  useEffect(() => {
    const getUserDetails = async () => {
      setRequestLoading(true);
      let response: any;
      if (user === ADMIN) {
        response = await getRequest(GET_USER_SETTINGS_URL);
      } else {
        response = await getRequest(GET_USER_ADMIN_SETTINGS_URL);
      }
      if (response?.success) {
        setFormData((prevData) => {
          return {
            ...prevData,
            name: response.data.name ?? "",
            administratorEmail: response.data.administratorEmail ?? "",
            autoDisplayOfTagInfo: response.data.autoDisplayTagInfo ?? false,
            officePhoneNumber: response.data.officePhoneNumber ?? "",
            officeEmailAddress: response.data.officeEmailAddress ?? "",
            randomiseEmployeePasscode:
              response.data.randomizeEmployeePassCode ?? false,
            checkCallProximityRadius:
              response.data.checkCallProximityRadius ?? "",
            proximityRadiusEmail: response.data.proximityRadiusEmail ?? "",
            shiftEndProximityRadius:
              response.data.shiftEndProximityRadius ?? "",
            shiftStartProximityRadius:
              response.data.shiftStartProximityRadius ?? "",
          };
        });
        setSettingsId(response.data.id);
      } else {
        if (response?.data) {
          toastError(response?.data);
        }
      }
      setRequestLoading(false);
    };
    getUserDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.main_container}>
      {requestLoading && <PageLoader />}
      <ScrollToTop />
      <DashboardHeader active="admin settings" />
      <div className={styles.container}>
        <AdminSideBar active="settings" />
        <div className={styles.item_container}>
          <SettingsNavigation active="general" />
          <div className={styles.content_container}>
            <Typography textAlign="left" textWeight="600" textSize="18px">
              General
            </Typography>
            <SettingsGeneralForm
              formData={formData}
              setFormData={setFormData}
              requiredFormData={requiredFormData}
              setRequiredFormData={setRequiredFormData}
              touchedFormData={touchedFormData}
              setTouchedFormData={setTouchedFormData}
            />
            <div className={styles.button_container}>
              <Button
                width="200px"
                text="Save"
                isPrimary={true}
                onClick={handleSubmitForm}
                isLoading={requestLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminGeneralSettings;
