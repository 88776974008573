import React from "react";
import styles from "./index.module.css";
import SignUpHeader from "components/Common/SignUpHeader";
import Typography from "components/Common/Typography";
import colors from "theme/colors";
import arrowLeftIcon from "assets/svg/arrow-left-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "components/Common/ScrollToTop";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <section>
      <ScrollToTop />
      <SignUpHeader />
      <div className={styles.container}>
        <div
          className={styles.top_text_container}
          onClick={() => navigate("/")}
        >
          <img src={arrowLeftIcon} alt="arrow-left-icon" />
          <Typography textSize="24px" textWeight="600" marginHorizontal={10}>
            Back
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textColor={colors.primary3}
            textSize="15px"
            textWeight="500"
            style={{ marginLeft: "55px" }}
          >
            Last Updated: November 4, 2024
          </Typography>
        </div>
        <div className={`${styles.top_text_header}`}>
          <Typography
            textWeight="600"
            textSize="48px"
            style={{
              marginBottom: "60px",
              marginTop: "20px",
            }}
          >
            Privacy Policy
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            Welcome to Glix, provided by Gyzer Technologies Ltd (“we,” “us,” or
            “our”). Your privacy is very important to us. This Privacy Notice
            explains how we collect, use, and protect your personal information
            when you use the Glix ("Glix" or "the App") through our website
            (www.glixapp.com) or mobile applications. By using Glix, you agree
            to the practices described in this policy
          </Typography>
        </div>

        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Information We Collect
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            // style={{ marginLeft: "-15px" }}
          >
            When you use Glix, we may collect the following information:
          </Typography>
          <ul className={styles.ul}>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                <span className={styles.title_li}>Personal Details: </span>
                Name, email address, phone number, job title, and other profile
                details provided by your employer or entered by you
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                <span className={styles.title_li}>
                  Employment Information:{" "}
                </span>
                Work schedules, holiday requests, attendance records, and
                time-off details.
              </li>
            </Typography>{" "}
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                <span className={styles.title_li}>Location Data: </span>
                Real-time location data when using features like predefined
                checkpoints or geofencing (subject to your consent).
              </li>
            </Typography>{" "}
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                <span className={styles.title_li}>Device Information: </span>
                Information about your device, such as model, operating system,
                and app version.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                <span className={styles.title_li}>Usage Data: </span>
                Information about how you use the App, including features
                accessed and interactions with notifications.
              </li>
            </Typography>
          </ul>
        </div>

        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            How We Use Your Information
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            We use your information to:
          </Typography>
          <ul className={styles.ul}>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                Facilitate workforce management functions such as holiday
                booking, attendance tracking, and shift scheduling.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                Enable location-based services like geofencing and predefined
                checkpoints.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                Ensure employee safety and well-being through features like
                Check Call.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                Communicate with you about app updates, features, or important
                notifications.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                Improve the App's functionality and user experience.
              </li>
            </Typography>
          </ul>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Sharing Your Information
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            // style={{ marginLeft: "-15px" }}
          >
            We may share your information with:
          </Typography>
          <ul className={styles.ul}>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                <span className={styles.title_li}>Your Employer: </span>
                To enable workforce management and ensure compliance with
                organisational requirements.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                <span className={styles.title_li}>
                  Third-Party Service Providers:{" "}
                </span>
                For services such as cloud storage, analytics, and app support.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                <span className={styles.title_li}>Legal Authorities: </span>
                If required by law or to protect rights, property, or safety.
              </li>
            </Typography>
          </ul>
        </div>

        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Data Security
          </Typography>
        </div>
        <div className={styles.information_container_text}>
          <Typography
            textColor={colors.gray1}
            textAlign="left"
            textSize="20px"
            style={{ marginLeft: "-15px" }}
          >
            We implement strict security measures to protect your data against
            unauthorised access, alteration, disclosure, or destruction. Our
            company is ISO 27001 accredited, adhering to the highest standards
            of information security.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Data Retention
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            We retain your data only for as long as necessary to fulfil the
            purposes outlined in this notice or as required by law.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Your Rights
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            You have the right to:
          </Typography>
          <ul className={styles.ul}>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                Access the personal data we hold about you.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                Request correction or deletion of inaccurate or unnecessary
                data.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                Withdraw consent for data processing where applicable.
              </li>
            </Typography>
            <Typography
              textAlign="left"
              marginVertical="0px"
              textColor={colors.gray1}
              textSize="20px"
            >
              <li className={styles.li}>
                Lodge a complaint with a data protection authority if you
                believe your rights have been violated.
              </li>
            </Typography>
          </ul>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            International Data Transfers
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            If your data is transferred outside the UK or EU, we ensure adequate
            safeguards are in place to protect your information in accordance
            with data protection laws.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Changes to this Notice
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            We may update this Privacy Notice periodically. Any changes will be
            communicated through the App or other appropriate means.
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography
            textAlign="left"
            textWeight="600"
            textSize="24px"
            marginVertical={20}
          >
            Contact Us
          </Typography>
        </div>
        <div className={styles.container_text}>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
            {/* <Link
              to="mailto:privacy@glixapp.com"
              style={{ color: colors.gray1 }}
            >
              privacy@glixapp.com
            </Link> */}
          </Typography>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            Gyzer Technologies Limited
          </Typography>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            20-22 Wenlock Road, London, N1 7GU
          </Typography>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            Email: support@glixapp.com
          </Typography>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            Tel: 02082438601
          </Typography>
          <Typography textColor={colors.gray1} textAlign="left" textSize="20px">
            Website: www.glixapp.com
          </Typography>
        </div>

        <div className={styles.container_text}>
          <Typography
            textColor={colors.primary3}
            textAlign="left"
            textSize="20px"
            marginVertical={30}
          >
            By using Glix, you consent to the practices described in this
            Privacy Policy.
          </Typography>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
