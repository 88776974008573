import React from "react";
import styles from "./index.module.css";
import TextInput from "../../../../Form/TextInput";
import { EmployeeIdentificationFormProps } from "../../../../../types-definition/StaffHub/employee";
import DateInput from "../../../../Form/DateInput";
import SelectInput from "../../../../Form/SelectInput";
import LocationSelectInput from "../../../../Form/LocationSelectInput";
import { Country } from "country-state-city";
import Typography from "../../../../Common/Typography";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  basicInformationFormData,
  basicInformationIdentificationFormDataNonUk,
  basicInformationVisaInformation,
  setBasicInformationFormData,
  setBasicInformationRequiredIdentificationFormDataNonUk,
  setBasicInformationVisaInformation,
} from "../../../../../redux/slice/staff-hub/employeeBasicInfoSlice";
import NumberInputWithoutInput from "../../../../Form/NumberInputWithoutIcon";

const EmployeeIdentificationForm: React.FC<
  EmployeeIdentificationFormProps
> = () => {
  const formData = useAppSelector(basicInformationFormData);
  const requiredFormData = useAppSelector(
    basicInformationIdentificationFormDataNonUk,
  );
  const requiredVisaInformationFormData = useAppSelector(
    basicInformationVisaInformation,
  );

  const dispatch = useAppDispatch();
  return (
    <>
      <div className={styles.form_flex_container}>
        <LocationSelectInput
          label="Country Of Citizenship"
          placeholder="Select the country of citizenship"
          value={formData.countryOfCitizenship}
          setValue={(data) => {
            dispatch(
              setBasicInformationFormData({
                ...formData,
                countryOfCitizenship: data,
              }),
            );
          }}
          options={Country.getAllCountries()}
          id="countryOfCitizenship"
        />
        <SelectInput
          label="Document Type"
          placeholder="Select Document Type"
          value={formData.nonUkDocumentType}
          setValue={(data) => {
            dispatch(
              setBasicInformationFormData({
                ...formData,
                nonUkDocumentType: data,
              }),
            );
            dispatch(
              setBasicInformationRequiredIdentificationFormDataNonUk({
                ...requiredFormData,
                nonUkDocumentType: false,
              }),
            );
          }}
          options={["None", "Passport", "National ID"]}
          error={requiredFormData.nonUkDocumentType}
          helperText={
            requiredFormData.nonUkDocumentType
              ? "Document Type is required"
              : ""
          }
          id="documentType"
          readonly={true}
        />
      </div>
      {formData.nonUkDocumentType !== "None" && (
        <div className={styles.form_flex_container}>
          <NumberInputWithoutInput
            label="Document Number"
            placeholder="Enter Document Number"
            value={formData.nonUkDocumentNumber}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  nonUkDocumentNumber: data,
                }),
              );
              dispatch(
                setBasicInformationRequiredIdentificationFormDataNonUk({
                  ...requiredFormData,
                  nonUkDocumentNumber: false,
                }),
              );
            }}
            error={requiredFormData.nonUkDocumentNumber}
            helperText={
              requiredFormData.nonUkDocumentNumber
                ? "Document Number is required"
                : ""
            }
            id="documentNumber"
            required={true}
            disabled={formData.nonUkDocumentType === "None"}
          />
          <DateInput
            value={formData.nonUkIssueDate}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  nonUkIssueDate: data,
                }),
              );
              dispatch(
                setBasicInformationRequiredIdentificationFormDataNonUk({
                  ...requiredFormData,
                  nonUkIssueDate: false,
                }),
              );
            }}
            label="Issue Date"
            placeholder="Select Date"
            required={true}
            error={requiredFormData.nonUkIssueDate}
            helperText={
              requiredFormData.nonUkIssueDate ? "Issue Date is required" : ""
            }
            disabled={formData.nonUkDocumentType === "None"}
            id="issueDate"
          />
          <DateInput
            value={formData.nonUkExpiryDate}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  nonUkExpiryDate: data,
                }),
              );
              dispatch(
                setBasicInformationRequiredIdentificationFormDataNonUk({
                  ...requiredFormData,
                  nonUkExpiryDate: false,
                }),
              );
            }}
            min={formData.nonUkIssueDate}
            validateMin={true}
            label="Expiry Date"
            placeholder="Select Date"
            required={true}
            error={requiredFormData.nonUkExpiryDate}
            helperText={
              requiredFormData.nonUkExpiryDate ? "Expiry Date is required" : ""
            }
            disabled={formData.nonUkDocumentType === "None"}
            id="expiryDate"
          />
        </div>
      )}
      {formData.nonUkDocumentType !== "None" && (
        <div className={styles.form_flex_container}>
          <TextInput
            label="Place of Issue"
            placeholder="Enter Place of Issue"
            value={formData.nonUkPlaceOfIssue}
            setValue={(data) => {
              dispatch(
                setBasicInformationFormData({
                  ...formData,
                  nonUkPlaceOfIssue: data,
                }),
              );
            }}
            disabled={formData.nonUkDocumentType === "None"}
          />
        </div>
      )}
      {/* {formData.documentType !=='None' &&()} */}
      <div className={styles.mid_text}>
        <Typography textAlign="left" textWeight="600" textSize="24px">
          Visa Information
        </Typography>
      </div>
      {/* {formData.documentType !=='None' &&()} */}
      <div className={styles.form_flex_container}>
        <SelectInput
          value={formData.visaType}
          setValue={(data) => {
            dispatch(
              setBasicInformationFormData({
                ...formData,
                visaType: data,
              }),
            );
            dispatch(
              setBasicInformationRequiredIdentificationFormDataNonUk({
                ...requiredFormData,
                visaType: false,
              }),
            );
            dispatch(
              setBasicInformationVisaInformation({
                ...requiredVisaInformationFormData,
                visaType: false,
              }),
            );
          }}
          label="Visa Type"
          placeholder="Enter Visa Type"
          required={true}
          error={
            requiredFormData.visaType ||
            requiredVisaInformationFormData.visaType
          }
          helperText={
            requiredFormData.visaType ||
            requiredVisaInformationFormData.visaType
              ? "Visa Type is required"
              : ""
          }
          options={[
            "Indefinite Leave to Remain (ILR)",
            "Student Visa",
            "Skilled Worker Visa",
            "Health and Care Worker Visa",
            "Intra-company Transfer Visa",
            "Tier 5 Youth Mobility Scheme Visa",
            "Start-up Visa",
            "Innovator Visa",
            "Ancestry Visa",
            "H-1B Visa",
            "L-1 Visa",
            "O-1 Visa",
            "TN Visa",
            "E-2 Visa",
            "H-2A Visa",
            "H-2B Visa",
            "Temporary Foreign Worker Program",
            "International Mobility Program",
            "Global Talent Stream",
            "NAFTA Work Permits",
            "Tier 2 General Visa",
            "Tier 2 Intra-company Transfer Visa",
            "Job Seeker Visa",
            "Work Visa (Employment Visa)",
            "Talent Passport (Passeport Talent)",
            "Salaried Employment Visa",
            "Highly Skilled Migrant Visa",
            "EU Blue Card",
            "Entrepreneur Visa",
            "Work Visa for Employees",
            "Z Visa",
            "R Visa",
            "Employment Visa (E Visa)",
            "Business Visa",
            "Engineer/Specialist in Humanities/International Services Visa",
            "Highly Skilled Professional Visa",
            "Specified Skilled Worker Visa",
            "Work Visa",
            "Investor Visa",
            "General Work Visa",
            "Critical Skills Visa",
            "Class D Work Permit",
            "Special Pass Visa",
            "Temporary Resident Visa",
            "Permanent Resident Visa",
            "Temporary Skill Shortage Visa",
            "Employer Nomination Scheme Visa",
            "Skilled Independent Visa",
            "Essential Skills Work Visa",
            "Talent (Accredited Employer) Visa",
            "Entrepreneur Work Visa",
            "Iqama (Work Permit)",
            "E-7 Visa",
            "D-8 Visa",
            "Turquoise Card",
          ].sort((a: string, b: string) => a.localeCompare(b))}
          // readonly={true}
        />
        <NumberInputWithoutInput
          value={formData.visaNumber}
          setValue={(data) => {
            dispatch(
              setBasicInformationFormData({
                ...formData,
                visaNumber: data,
              }),
            );
            dispatch(
              setBasicInformationRequiredIdentificationFormDataNonUk({
                ...requiredFormData,
                visaNumber: false,
              }),
            );
            dispatch(
              setBasicInformationVisaInformation({
                ...requiredVisaInformationFormData,
                visaNumber: false,
              }),
            );
          }}
          label="Visa Number"
          placeholder="Enter Visa Number"
          required={true}
          error={
            requiredFormData.visaNumber ||
            requiredVisaInformationFormData.visaNumber
          }
          helperText={
            requiredFormData.visaNumber ||
            requiredVisaInformationFormData.visaNumber
              ? "Visa Number is required"
              : ""
          }
        />
      </div>
      {/* {formData.documentType !=='None' &&()} */}
      <div className={styles.form_flex_container}>
        <DateInput
          value={formData.visaStartDate}
          setValue={(data) => {
            dispatch(
              setBasicInformationFormData({
                ...formData,
                visaStartDate: data,
              }),
            );
            dispatch(
              setBasicInformationRequiredIdentificationFormDataNonUk({
                ...requiredFormData,
                visaStartDate: false,
              }),
            );
            dispatch(
              setBasicInformationVisaInformation({
                ...requiredVisaInformationFormData,
                visaStartDate: false,
              }),
            );
          }}
          label="Issue Date"
          placeholder="Select Date"
          required={true}
          error={
            requiredFormData.visaStartDate ||
            requiredVisaInformationFormData.visaStartDate
          }
          helperText={
            requiredFormData.visaStartDate ||
            requiredVisaInformationFormData.visaStartDate
              ? "Visa Start Date is required"
              : ""
          }
        />
        <DateInput
          value={formData.visaExpiryDate}
          setValue={(data) => {
            dispatch(
              setBasicInformationFormData({
                ...formData,
                visaExpiryDate: data,
              }),
            );
            dispatch(
              setBasicInformationRequiredIdentificationFormDataNonUk({
                ...requiredFormData,
                visaExpiryDate: false,
              }),
            );
            dispatch(
              setBasicInformationVisaInformation({
                ...requiredVisaInformationFormData,
                visaExpiryDate: false,
              }),
            );
          }}
          min={formData.visaStartDate}
          validateMin={true}
          label="Expiry Date"
          placeholder="Select Date"
          required={true}
          error={
            requiredFormData.visaExpiryDate ||
            requiredVisaInformationFormData.visaExpiryDate
          }
          helperText={
            requiredFormData.visaExpiryDate ||
            requiredVisaInformationFormData.visaExpiryDate
              ? "Visa Expiry Date is required"
              : ""
          }
        />
      </div>
    </>
  );
};

export default EmployeeIdentificationForm;
