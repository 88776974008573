import React from "react";
import Typography from "../../Common/Typography";
import styles from "./index.module.css";
import { RadioInputProps } from "../../../types-definition/form";
import colors from "../../../theme/colors";

const RadioInput: React.FC<RadioInputProps> = ({
  label,
  width = "100%",
  checkboxWidth = 20,
  value,
  setValue,
  marginVertical,
  border = false,
  name,
  disabled = true,
  labelWeight,
  style,
}) => {
  return (
    <div
      className={
        border ? `${styles.container} ${styles.border}` : styles.container
      }
      style={{
        width,
        marginTop: marginVertical,
        marginBottom: marginVertical,
        padding: border ? "10px" : "0",
        ...style,
      }}
    >
      <input
        type="radio"
        onChange={(e) => {
          return setValue && setValue(e.target.checked)
        }}
        // value={value}
        checked={value}
        name={name}
        style={{
          width: checkboxWidth,
          height: checkboxWidth,
          borderColor: colors.gray1,
          marginRight: "10px",
          accentColor: colors.primary3,
        }}
        className={styles.input}
        disabled={!disabled}
      />
      <Typography textWeight={labelWeight}>{label}</Typography>
    </div>
  );
};

export default RadioInput;
